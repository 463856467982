<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
import createNotify from "@/utils/notify.js"
export default {
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      visibility: true,
      info: '您有新消息，请及时处理。',
      timer: ''
    }
  },
  created() {
    document.addEventListener('visibilitychange', () => {
      // 用户息屏、或者切到后台运行 （离开页面） 
      if (document.visibilityState === 'hidden') {
        this.visibility = false
        // console.log('隐藏了')
      }
      // 用户打开或回到页面 
      if (document.visibilityState === 'visible') {
        this.visibility = true
        // console.log('页面可见')
      }
    })

    const LOGIN_INFO = localStorage.getItem("LOGIN_INFO")
      ? JSON.parse(localStorage.getItem("LOGIN_INFO"))
      : "";
    localStorage.removeItem("LuckyDraw"); // 移除用户通过公众号打开抽奖页的标识
    if (LOGIN_INFO != "") {
      if (this.judgeWx()) {
        let time = localStorage.getItem("loginTime")
          ? JSON.parse(localStorage.getItem("loginTime"))
          : "";
        if (time) {
          let now = new Date().getTime();
          let distance = (now - time) / 1000 / 60 / 60 / 24;
          // alert("distance:" + distance)
          if (distance > 2) {
            localStorage.clear();
            return;
          }
        }
      }
      // 获取工单属性
      this.$store.dispatch("order/getRepairFrom", () => { });

      // 请求用户信息
      this.$store.dispatch("aquireUserInfo", () => {
        this.$store.dispatch("chat/getUserInfoByAccount", {
          callback: () => {
            this.$store.dispatch("chat/createSocket");
            this.dealIM();
          },
        });
        this.$store.dispatch("getAccessToken");
      });
      this.$store.commit("updateLoginStatus", true);

      this.$store.dispatch("getAutomaticReply")
    }
    this.judgeScreen();

    // 处理页面改变时的自适应
    window.addEventListener("resize", this.debounce(this.judgeScreen, 500));

    this.findLogo();

    // vuex 保存$EventBus
    this.$store.commit("updateEventBus", this.$EventBus);

    // 处理im相关
    this.$EventBus.$on("dealIM", this.dealIM);

    // 订阅新消息提示
    window.$EventBus.$on('newsNotify', () => {
      // console.log("接收到通知了")
      this.newNotify()
    })
    // 清理标题通知
    window.$EventBus.$on('clearTitleNotify', () => {
      clearInterval(this.timer)
      this.timer = null
      document.title = "火影Firebat笔记本官网"
    })
  },
  mounted() {
    // 集中处理弹窗事件（移动端和pc端）
    this.$EventBus.$on("toast", ({ type, message }) => {
      if (this.isMobile) {
        this.$toast(message, {
          position: "center", // 'bottom' | 'center',
          duration: "1500",
        });
      } else {
        this.$message[type](message);
      }
    });

    Notification.requestPermission().then(res => {
      // denied 或 granted
      console.log(res)
    })
  },
  methods: {
    /**
     * @description: 新消息提示
     * @return {*}
     */
    newNotify() {
      this.$notify({
        title: '',
        message: '有新消息',
        customClass: "notify_class"
      });
      console.log('visibility', this.visibility)
      if (!this.visibility) {
        createNotify({
          title: '通知',
          options: {
            badge: "http://file.firebat.com.cn/FlHw6QFpiP5WG0448jldwG9pGf6Q",
            body: '您有新消息，请及时查看'
          },
          duration: 5000
        })
      }
      if (this.$route.fullPath != "/index/sales/chat") {
        if (!this.timer) {
          this.timer = setInterval(() => {
            document.title = this.info.substring(1, this.info.length) + this.info.substring(0, 1)
            this.info = document.title.substring(0, this.info.length)
          }, 500);
        }
      }
    },
    // 处理IM
    dealIM() {
      this.$store.dispatch("chat/searchOnlineState", {
        callback: (res) => {
          // 不是客服
          if (!this.userInfo.isService) {
            // console.log("查询在线状态", res);
            let serviceList = res.data.filter((item) => {
              return item.is_online;
            });
            // console.log("在线客服", serviceList)
            serviceList.length > 0 ? "" : (serviceList = res.data);
            // console.log("过滤的在线客服",serviceList)
            let service = localStorage.getItem("saveServe"); // 本地存储的上一次聊天的客服
            let index;
            if (service) {
              service = JSON.parse(service);
              service[this.userInfo.userId]
                ? (index = res.data.findIndex((item) => {
                  return item.user_id;
                }))
                : (index = -1);
            }
            index > -1
              ? ""
              : serviceList.length == 1
                ? (index = 0)
                : (index = Math.floor(Math.random() * serviceList.length));
            // console.log("数据初始化",data,target,index,data[index])
            // this.$store.dispatch("chat/getUserInfoByAccount", {
            //   id: serviceList[index].userId,
            //   type: "searchService",
            //   callback: (id) => {
            //     this.$store.commit("chat/updateCurrentSessionUser", {
            //       realname: "客服",
            //       user_id: id,
            //     });
            //     if (service) {
            //       service[this.userInfo.userId] = id;
            //       localStorage.setItem("saveServe", JSON.stringify(service));
            //     } else {
            //       localStorage.setItem(
            //         "saveServe",
            //         JSON.stringify({
            //           [this.userInfo.userId]: id,
            //         })
            //       );
            //     }
            //   },
            // });
            this.$store.commit("chat/updateCurrentSessionUser", {
              realname: "客服",
              user_id: serviceList[index].user_id,
            });
            if (service) {
              service[this.userInfo.userId] = serviceList[index].user_id;
              localStorage.setItem("saveServe", JSON.stringify(service));
            } else {
              localStorage.setItem(
                "saveServe",
                JSON.stringify({
                  [this.userInfo.userId]: serviceList[index].user_id,
                })
              );
            }
          }

        }
      })
      this.$store.dispatch("chat/getChatList", {
        page: 1,
        callback: () => { },
      });
    },
    /**
     * @description: 节流
     * @param {Function} fn 要执行的函数
     * @param {Number} interval 时间
     * @return {*}
     */
    throttle(fn, interval) {
      var timer;
      let clearTimer;
      var isFirstTime = true;
      var flag = true
      timer ? clearTimeout(timer) : ""
      return function () {
        var that = this;
        var args = arguments; //获取参数
        // console.log("isFirstTime", isFirstTime)
        if (isFirstTime) {//第一次直接调用                
          fn.apply(that, args);
          isFirstTime = false
        }
        if (timer) {
          return false;
        }
        clearTimer = setTimeout(() => {
          flag = false;
          clearTimeout(clearTimer)
        }, interval || 200)
        timer = setTimeout(function () {
          // console.log("throttle---");
          if (!flag) {
            fn.apply(that, args);
          }
          clearTimeout(timer);
          timer = null;
        }, interval || 200)//给一个默认时间        
      }
    },

    /**
     * @description: 防抖
     * @param {Function} fn 函数
     * @param {Number} wait 时间
     * @return {*}
     */
    debounce(fn, wait) {
      var timer = null;
      return function () {
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, wait);
      };
    },
    // 判断移动端标识
    judgeScreen() {
      if (document.body.clientWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      localStorage.setItem("isMobile", this.isMobile);
      this.$store.commit("updatIsMobile", this.isMobile);
    },
    // 获取logo
    findLogo() {
      this.$request({
        method: "get",
        url: this.$requestPath.findLogo,
        data: {},
      })
        .then((res) => {
          if (res.code == 0) {
            this.$store.commit("updateLogo", res.data);
          } else {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: "获取logo图片失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取logo失败", error);
        });
    },
  },
};
</script>
<style lang="scss">
.el-notification__content {
  margin: 0 !important;
}

.notify_class {
  width: 12.5rem !important;
}

@media screen and (max-width: 640px) {
  .notify_class {
    width: 9.375rem !important;
  }
}


@import "./style/index.scss";

html,
body {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.inner_box {
  max-width: 1200px;
  width: 100vw;
  height: 100vh;
}

.cursor:hover {
  cursor: pointer;
}

.custom_input {
  background-color: #ffffff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.el-form-item__label {
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pc_select_show {
    display: none !important;
  }

  .phone_select_show {
    display: block !important;
  }

  .el-form-item__label {
    font-size: 0.8125rem;
  }

  .el-upload--picture-card {
    width: 6.25rem;
    height: 6.25rem;
  }

  .el-upload {
    width: 6.25rem;
    height: 6.25rem;
    line-height: 6.25rem;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 6.25rem;
    height: 6.25rem;
    line-height: 6.25rem;
  }

  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 6.25rem;
    height: 6.25rem;
    line-height: 6.25rem;
  }

  .avatar {
    width: 6.25rem;
    height: 6.25rem;
  }

  .null_box {
    font-size: 0.75rem;
  }

  .null {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 640px) {
  .pc_select_show {
    display: block !important;
  }

  .phone_select_show {
    display: none !important;
  }

  .null_box {
    font-size: 0.875rem;
  }

  .null {
    font-size: 0.875rem;
  }
}

.null_box {
  border: 0.0625rem solid #ccc;
}

.wc-messagebox__btn--then {
  color: #2c3e50 !important;
}

.wc-messagebox__btn--catch {
  color: red !important;
}

.address_span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}
</style>
