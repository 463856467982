/*
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2022-12-30 15:51:55
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-07-17 10:11:35
 * @FilePath: /com.huizhouyiren.B0805/src/utils/utils.js
 * @Description: 封装的工具函数
 */
// 获取屏幕工作区域的高度和宽度（去掉状态栏）
export function getScreenHeight() {
  return document.documentElement.clientHeight;
}
export const orderStatusMap = {
  1: "审核中",
  2: "审核成功",
  3: "审核失败",
  4: "运输中",
  5: "维修中",
  6: "完成",
  7: "用户已确认",
  8: "已签收",
  9: "拒签",
};

export function generateRandId() {
  var d = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
    c
  ) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

let timeout = null;
/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export function debounce(func, wait = 500, immediate = false) {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout);
  // 立即执行，此类情况一般用不到
  if (immediate) {
    var callNow = !timeout;
    timeout = setTimeout(function() {
      timeout = null;
    }, wait);
    if (callNow) typeof func === "function" && func();
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function() {
      typeof func === "function" && func();
    }, wait);
  }
}
var timer, flag;
/**
 * 节流原理：在一定时间内，只能触发一次
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */

export function throttle(func) {
  var wait =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  var immediate =
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (immediate) {
    if (!flag) {
      flag = true; // 如果是立即执行，则在wait毫秒内开始时执行

      typeof func === "function" && func();
      timer = setTimeout(function() {
        flag = false;
      }, wait);
    }
  } else {
    if (!flag) {
      flag = true; // 如果是非立即执行，则在wait毫秒内的结束处执行

      timer = setTimeout(function() {
        flag = false;
        typeof func === "function" && func();
      }, wait);
    }
  }
}

export function getTime(type, text, timestamp) {
  /**
   * @type 类型
   * @text 拼接的字符串
   */
  let date = timestamp ? new Date(timestamp) : new Date();
  let Y = "";
  let M = "";
  let D = "";
  if (type == "Chinese") {
    Y = date.getFullYear() + "年";
    M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "月";
    D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
    return Y + M + D;
  }
  if (type == "full") {
    Y = date.getFullYear() + text;
    M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + text;
    D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    const h =
      date.getHours() < 10
        ? "0" + date.getHours() + ":"
        : date.getHours() + ":";
    const m =
      date.getMinutes() < 10
        ? "0" + date.getMinutes() + ":"
        : date.getMinutes() + ":";
    const s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 秒
    return Y + M + D + h + m + s;
  }

  if (type == "simple") {
    Y = date.getFullYear() + text;
    M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + text;
    D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return Y + M + D;
  }

  if (type == "time") {
    const h =
      date.getHours() < 10
        ? "0" + date.getHours() + ":"
        : date.getHours() + ":";
    const m =
      date.getMinutes() < 10
        ? "0" + date.getMinutes() + ":"
        : date.getMinutes() + ":";
    const s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 秒
    return h + m + s;
  }
}
