import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
    meta: {
      permissions: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
    meta: {
      permissions: false,
    },
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/index/Index.vue"),
    redirect: "/index/home",
    meta: {
      permissions: false,
    },
    children: [
      {
        path: "home",
        component: () => import("../views/index/pages/Home.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "inspection",
        component: () => import("../views/index/pages/personal/Inspection.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "activity",
        component: () => import("../views/index/pages/Activity.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "common",
        component: () => import("../views/index/pages/navbarPages/Common.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "resources",
        component: () => import("../views/index/pages/serve/Resources.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "stores",
        component: () => import("../views/index/pages/serve/Stores.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "custom",
        component: () => import("../views/index/pages/custom.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "agreement",
        component: () => import("../views/index/pages/agreement.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "serve",
        component: () => import("../views/index/pages/navbarPages/Serve.vue"),
        redirect: "/index/serve/index",
        meta: {
          permissions: false,
        },
        children: [
          {
            path: "index",
            component: () => import("../views/index/pages/serve/Index.vue"),
            meta: {
              permissions: false,
            },
          },
          {
            path: "drive",
            component: () => import("../views/index/pages/serve/Drive.vue"),
            meta: {
              permissions: false,
            },
          },

          {
            path: "wallpaper",
            component: () => import("../views/index/pages/serve/Wallpaper.vue"),
            meta: {
              permissions: false,
            },
          },
        ],
      },
      {
        path: "warranty",
        component: () => import("../views/index/pages/warranty/Warranty.vue"),
        redirect: "/index/warranty/qurey",
        children: [
          {
            path: "qurey",
            component: () => import("../views/index/pages/warranty/Query.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "apply",
            component: () => import("../views/index/pages/warranty/Apply.vue"),
            meta: {
              permissions: true,
            },
          },
        ],
      },
      {
        path: "brand",
        component: () => import("../views/index/pages/navbarPages/Brand.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "community",
        component: () =>
          import("../views/index/pages/navbarPages/Community.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "communityinfo",
        component: () =>
          import("../views/index/pages/navbarPages/CommunityInfo.vue"),
        meta: {
          permissions: false,
        },
      },
      // CommunityInfo
      {
        path: "content",
        component: () => import("../views/index/pages/navbarPages/Content.vue"),
        meta: {
          permissions: false,
        },
      },
      {
        path: "sales",
        component: () => import("../views/index/pages/navbarPages/Sales.vue"),
        redirect: "/index/sales/equipment",
        meta: {
          permissions: true,
        },
        children: [
          {
            path: "equipment",
            component: () => import("../views/index/pages/sales/Equipment.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "info",
            component: () => import("../views/index/pages/sales/Info.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "logistics",
            component: () => import("../views/index/pages/sales/Logistics.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "chat",
            component: () => import("../views/index/pages/sales/chat.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "orderDetail", // 工单详情
            component: () =>
              import("../views/index/pages/sales/orderDetail.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "orderLogistics", // 工单快递信息
            component: () =>
              import("../views/index/pages/sales/orderLogistics.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "write",
            component: () => import("../views/index/pages/sales/write.vue"),
            meta: {
              permissions: true,
            },
          },
        ],
      },
      {
        path: "personal",
        component: () =>
          import("../views/index/pages/navbarPages/Personal.vue"),
        redirect: "/index/personal/message",
        meta: {
          permissions: true,
        },
        children: [
          {
            path: "message",
            component: () =>
              import("../views/index/pages/personal/Message.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "collection",
            component: () =>
              import("../views/index/pages/personal/Collection.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "integral",
            component: () =>
              import("../views/index/pages/personal/Integral.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "history",
            component: () =>
              import("../views/index/pages/personal/History.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "serve",
            component: () => import("../views/index/pages/personal/Serve.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "equipment",
            component: () =>
              import("../views/index/pages/personal/Equipment.vue"),
            meta: {
              permissions: true,
            },
          },
          // {
          //   path: "inspection",
          //   component: () =>
          //     import("../views/index/pages/personal/Inspection.vue"),
          //   meta: {
          //     permissions: true,
          //   },
          // },
          {
            path: "address",
            component: () =>
              import("../views/index/pages/personal/Address.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "account",
            component: () =>
              import("../views/index/pages/personal/Account.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "common",
            component: () => import("../views/index/pages/personal/Common.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "record",
            component: () => import("../views/index/pages/personal/Record.vue"),
            meta: {
              permissions: true,
            },
          },
          {
            path: "exchange",
            component: () =>
              import("../views/index/pages/personal/Exchange.vue"),
            meta: {
              permissions: true,
            },
          },
        ],
      },
      {
        path: "modify",
        component: () => import("../views/index/pages/personal/Modify.vue"),
        meta: {
          permissions: true,
        },
      },
      {
        path: "certification",
        component: () =>
          import("../views/index/pages/personal/Certification.vue"),
        meta: {
          permissions: true,
        },
      },
    ],
  },
  {
    path: "/award",
    component: () => import("../views/award/Award.vue"),
    meta: {
      permissions: false,
    },
  },
  {
    path: "/record",
    component: () => import("../views/award/Record.vue"),
    meta: {
      permissions: false,
    },
  },
  {
    path: "/logistics",
    component: () => import("../views/award/Logistics.vue"),
    meta: {
      permissions: false,
    },
  },
];

/**
 * 以下四行代码是为了解决下面的这个问题
 * 关于VUE项目中报NavigationDuplicated: Avoided redundant navigation to current location 的错
 */
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 权限判断
router.beforeEach((to, from, next) => {
  // console.log("权限判断", to.meta.permissions);
  if (to.meta.permissions) {
    setTimeout(() => {
      if (store.state.isLogin) {
        next();
        window.scrollTo(0, 0);
      } else {
        store.commit("permissionNotice");
        setTimeout(() => {
          router.push({ path: "/login?pageType=login" });
        }, 1000);
      }
    }, 10);
  } else {
    next();
    window.scrollTo(0, 0);
  }
});

export default router;
