import Vue from "vue";
import Vuex from "vuex";
import request from "../api/request";
import requestPath from "../api/path";
import router from "@/router/index.js";
import { CodeToText } from "element-china-area-data";
import chat from "./chat.js";
import order from "./order";

function judgeWx() {
  var is_weixin = (function() {
    return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
  })();
  if (is_weixin) {
    return true;
  } else {
    return false;
  }
}

Vue.use(Vuex);

// 地址转换
function getCodeToText(codeStr) {
  let codeArray = codeStr.split(",");
  let area = "";
  switch (codeArray.length) {
    case 1:
      area += CodeToText[codeArray[0]];
      break;
    case 2:
      area += CodeToText[codeArray[0]] + CodeToText[codeArray[1]];
      break;
    case 3:
      area +=
        CodeToText[codeArray[0]] +
        CodeToText[codeArray[1]] +
        CodeToText[codeArray[2]];
      break;
    default:
      break;
  }
  return area;
}

const store = new Vuex.Store({
  modules: {
    chat,
    order,
  },
  state: {
    isLogin: false,
    collection: {
      content: [],
      wallpaper: [],
    },
    equipment: [],
    qiniuToken: "",
    cdnUrl: "",
    userInfo: {
      isServer: false,
    }, // 用户信息
    logoPic: {
      login: "", // 登录页logo
      head: "", // 首页logo
      background: "", // 首页背景图
      advertising: "", // 自助页广告
      customer: "", // 客服链接
      customerImage: "", // 客服图片
    },
    $EventBus: null, // 事件车
    classification: [], // 一级分类
    exchangeProduct: {}, // 选择的积分兑换商品
    addressList: [], // 收货地址
    isMobile: false, // 是否是移动端
    autoReplaySetting: {}, // 自动回复配置
  },

  mutations: {
    // 更细移动端标识
    updatIsMobile(state, flag) {
      state.isMobile = flag;
    },
    // 更新的登录状态
    updateLoginStatus(state, flag) {
      state.isLogin = flag;
      if (!state.isLogin) {
        localStorage.removeItem("LOGIN_INFO");
        router.replace({ path: "/" });
      }
    },
    // 更新用户信息
    updateUserInfo(state, userInfo) {
      state.userInfo = Object.assign({}, state.userInfo, userInfo);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    // 更新用户收藏
    updateCollection(state, collection) {
      state.collection = Object.assign({}, state.collection, collection);
      // state.collection.push(collection);
    },
    //保存$EventBus
    updateEventBus(state, $EventBus) {
      state.$EventBus = $EventBus;
    },
    // 本地删除收藏
    delCollect(state, data) {
      let { type, id } = data;
      let collection = state.collection;
      let wallpaper = collection.wallpaper;
      let content = collection.content;
      let list = [];
      if (type == "wallpaper") {
        list = wallpaper.filter((item) => {
          return item.wallpaperId != id;
        });
        collection = {
          wallpaper: list,
          content,
        };
      } else {
        list = content.filter((item) => {
          return item.tidingsId != id;
        });
        collection = {
          wallpaper,
          content: list,
        };
      }
      state.collection = Object.assign({}, state.collection, collection);
    },
    // 更新用户设备
    updateEquipment(state, equipment) {
      state.equipment = [];
      state.equipment.push(...equipment);
    },
    // 更新七牛授权token
    upDateQiniuToken(state, token) {
      state.qiniuToken = token;
    },
    // 更新CDN地址
    updateCDNUrl(state, url) {
      state.cdnUrl = url;
    },
    // 更新logo
    updateLogo(state, data) {
      data.forEach((item) => {
        if (item.code == "home") {
          state.logoPic.head = item.picUrl;
        }
        if (item.code == "login") {
          state.logoPic.login = item.picUrl;
        }
        if (item.code == "background") {
          state.logoPic.background = item.picUrl;
        }
        if (item.code == "advertising") {
          state.logoPic.advertising = item.picUrl;
        }
        if (item.code == "customer") {
          state.logoPic.customer = item.linkUrl;
          state.logoPic.customerImage = item.picUrl;
        }
      });
    },
    // 权限通知 (全局导航守卫中触发)
    permissionNotice(state) {
      state.$EventBus.$emit("toast", {
        type: "error",
        message: "需要登录后才能访问，请先登录",
      });
    },
    // 更新获取到的一级分类
    updatePrimaryClassification(state, classification) {
      state.classification = [];
      state.classification.push(...classification);
    },
    // 更新积分商品
    updateExchangeProduct(state, product) {
      state.exchangeProduct = Object.assign({}, state.exchangeProduct, product);
    },
    // 公众号授权
    accountAuthorization(state, path) {
      // let appid = "wx23608f16f345a81b";
      let appid = "wxe7ac0dff91c357cc";

      // let redirect_uri = encodeURIComponent(`http://huoying.yr966.com${path}`);
      let redirect_uri = encodeURIComponent(
        `${process.env.VUE_APP_CALLBACK_URL}${path}`
      );
      // alert("执行微信公众号授权");
      // setTimeout(() => {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirec`;
      // }, 2000);
    },
    // pc端微信授权
    pcAuthorization() {
      //   // console.log(WxLogin)
      let appid = "wx27c8dbd26229fd85";
      // let redirect_uri = encodeURIComponent("http://huoying.yr966.com/index");
      let redirect_uri = encodeURIComponent(
        `${process.env.VUE_APP_CALLBACK_URL}/index`
      );
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=3d6be0a4035d839573b04816624a415e#wechat_redirect`;
    },
    updateAutoReplaySetting(state, payload) {
      state.autoReplaySetting = payload;
    },
  },
  actions: {
    // 获取用户信息
    aquireUserInfo({ state, commit }, callback) {
      request({
        method: "get",
        url: requestPath.getUserInfo,
        data: {},
      })
        .then((res) => {
          // console.log("获取用户信息", res);
          if (res.code == 0) {
            commit("updateUserInfo", res.data);
            callback ? callback() : "";
          } else {
            state.$EventBus.$emit("toast", {
              type: "error",
              message: "获取个人信息失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取用户信息", error);
        });
    },
    // 获取用户收藏
    aquireCollection({ commit }, $EventBus) {
      request({
        method: "get",
        url: requestPath.collectList,
        data: {},
      })
        .then((res) => {
          // console.log("获取用户收藏", res);
          if (res.code == 0) {
            commit("updateCollection", res.data);
          } else {
            $EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取用户信息", error);
        });
    },
    // 获取用户设备
    aquireEquipment({ commit }, $EventBus) {
      request({
        method: "get",
        url: requestPath.equipment,
        data: {},
      })
        .then((res) => {
          // console.log("获取用户设备", res);
          if (res.code == 0) {
            commit("updateEquipment", res.data);
          } else {
            $EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取用户设备", error);
        });
    },
    // 收藏
    collectOpration(context, parameter) {
      let { relationId, type, $EventBus } = parameter;
      request({
        method: "post",
        url: requestPath.collect,
        data: {
          relationId,
          type,
        },
      })
        .then((res) => {
          // console.log("收藏", res);
          if (res.code == 0) {
            $EventBus.$emit("toast", { type: "success", message: "收藏成功" });
            type == 1
              ? $EventBus.$emit("wallpaperOperation", relationId)
              : $EventBus.$emit("contentOperation", relationId);
          } else {
            $EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("收藏失败", error);
        });
    },
    // 取消收藏
    cannelUserCollection({ commit }, data) {
      let { type, item, $EventBus } = data;
      request({
        method: "delete",
        url: requestPath.delCollect,
        data: {
          relationId: type == "wallpaper" ? item.wallpaperId : item.tidingsId,
          type: type == "wallpaper" ? 1 : 2,
        },
      })
        .then((res) => {
          // console.log("取消收藏", res);
          if (res.code == 0) {
            $EventBus.$emit("toast", {
              type: "success",
              message: "取消收藏成功",
            });
            // 本地删除收藏
            commit("delCollect", {
              type,
              id: type == "wallpaper" ? item.wallpaperId : item.tidingsId,
            });
          }
          // else {
          //     this.$EventBus.$emit("toast", {
          //         type: "error",
          //         message: res.msg,
          //     });
          // }
        })
        .catch((error) => {
          console.log("取消收藏", error);
        });
    },
    // 获取七牛授权token
    getAccessToken({ commit }) {
      request({
        method: "get",
        url: requestPath.getAccessToken,
        data: {},
      })
        .then((res) => {
          // console.log("获取七牛云token", res);
          if (res.code == 0) {
            commit("upDateQiniuToken", res.data.accessToken);
            commit("updateCDNUrl", res.data.domain);
          } else {
            window.$EventBus.$emit("toast", {
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log("获取七牛授权token报错", error);
        });
    },
    // 获取收货地址
    aquireAddress({ state }) {
      request({
        method: "get",
        url: requestPath.getAddress,
        data: {},
      })
        .then((res) => {
          if (res.code == 0) {
            // console.log(res);
            res.data.forEach((item) => {
              item.codeToAddress = "";
              if (item.cityCode) {
                item.codeToAddress = getCodeToText(item.cityCode);
              }
              item.detailAddress = item.codeToAddress + item.address;
              item.label = item.detailAddress;
              item.value = item.receiptId;
            });
            state.addressList = [];
            state.addressList.push(...res.data);
          } else {
            state.$EventBus.$emit("toast", {
              type: "error",
              message: "获取收货地址失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取收货地址error", error);
        });
    },
    // 微信登录、qq登录
    wechatLogin({ state, commit }, { code, loginType }) {
      // alert(`微信登录${code}`);
      request({
        method: "post",
        url: requestPath.login,
        data: {
          code,
          loginType,
        },
      })
        .then((res) => {
          // console.log("微信登录", res);
          if (res.code == 0) {
            state.$EventBus.$emit("toast", {
              type: "success",
              message: "登录成功",
            });
            // 存储登录用户信息
            localStorage.setItem("LOGIN_INFO", JSON.stringify(res.data));
            commit("updateLoginStatus", true); // 更新登录状态

            if (judgeWx()) {
              let time = new Date();
              localStorage.setItem("loginTime", JSON.stringify(time.getTime()));
              // 微信登录环境通知Home页面弹出签到框
              state.$EventBus.$emit("showSignIn");
            }
          } else {
            state.$EventBus.$emit("toast", {
              type: "error",
              message: "登录返回的结果" + res.msg,
            });
          }
        })
        .catch((error) => {
          console.log("微信登录error", error);
        });
    },
    // 获取自动回复设置
    getAutomaticReply({ commit }) {
      request({
        url: requestPath.automaticReply,
        method: "GET",
        params: {},
      }).then((res) => {
        if (res.code == "") {
          commit("updateAutoReplaySetting", res.data);
        }
      });
    },
  },
  getters: {
    // 获取登录状态
    getLoginStatus: (state) => state.isLogin,
    // 获取用户信息
    getUserInfo: (state) => state.userInfo,
    // 获取用户收藏
    getCollection: (state) => state.collection,
    // 获取用户设备
    getEquipment: (state) => state.equipment,
    // 获取七牛云token
    getQiniuToken: (state) => state.qiniuToken,
    // 获取CDN地址
    getQinniuUrl: (state) => state.cdnUrl,
    // 获取logo
    getLogo: (state) => state.logoPic,
    // 获取一级分类
    getPrimaryClassification: (state) => state.classification,
    // 获取积分商品
    getExchangeProduct: (state) => state.exchangeProduct,
    // 获取收货地址
    getAddressList: (state) => state.addressList,
  },
});
export default store;
