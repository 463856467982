/*
 * @FilePath: order.js
 * @Descripttion: 工单相关数据
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-18 16:09:51
 * @LastEditors: 李金深
 * @LastEditTime: 2023-02-01 10:57:21
 */

import request from "../api/request";
import requestPath from "../api/path";
export default {
  namespaced: true,
  state: {
    orderFiledList: [], // 工单属性
  },
  mutations: {
    /**
     * 更新工单属性
     * @param {*} state
     * @param {Array} list 获取的工单列表
     */
    updateOrderFiledList(state, list) {
      state.orderFiledList = [];
      state.orderFiledList.push(...list);
    },
  },
  actions: {
    /**
     * 获取工单的表单属性
     * @param {*} 
     * @param {Function} callback 回调函数
     */
    getRepairFrom({commit}, callback) {
      request({
        method: "get",
        url: requestPath.repairFrom,
        data: {},
      }).then((res) => {
        // console.log("获取的表单", res);
        if (res.code == 0) {
          res.data.forEach((item) => {
            item.value = "";
            item.list = [];
            item.uploadList = [];
          });
          // 更新工单属性
          commit("updateOrderFiledList",res.data)

          callback && callback(res.data);
        } else {
          window.$EventBus.$emit("toast", {
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    /**
     * 获取工单详情
     * @param {*} _
     * @param {Object} warrantyId 工单id callback 回调函数
     */
    getRepairInfo(_, { warrantyId, callback }) {
      request({
        url: requestPath.equipmentDetails,
        method: "GET",
        params: {
          warrantyId,
        },
      }).then((res) => {
        // console.log("获取的维修单详情", res);
        if (res.code == 0) {
          callback && callback(res.data);
        } else {
          window.$EventBus.$emit("toast", { type: "error", message: res.msg });
        }
      });
    },
  },
  getters: {},
};
