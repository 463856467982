<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2022-12-30 09:24:55
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-07-27 17:03:26
 * @FilePath: /com.huizhouyiren.B0805/src/components/mobilePopup/MobilePopup.vue
 * @Description: 手机端弹窗
-->
<template>
  <div class>
    <el-drawer title :visible.sync="drawer" :direction="direction" :before-close="handleClose" :show-close="false"
      :withHeader="false" size="55%">
      <div style="height: 55vh;width: 100vw;" class="flex flex-col">
        <div class="p-4" style="border:.0625rem solid #cccc;">
          <el-input v-model="keyword" placeholder="请输入关键字搜索"></el-input>
        </div>
        <div style="overflow-y: scroll;" class="flex-1 py-2">
          <div v-if="dataList.length > 0">
            <div v-for="(item, index) in dataList" :key="index" class="flex items-center px-4 py-4 item"
              @click="selectItem(item)">
              <div style="width:40px">
                <div class="flex items-center text-sm cursor-pointer">
                  <span style="height:.875rem;width: .875rem;box-sizing: border-box;border:.0625rem solid #cccc; "
                    class="flex items-center justify-center mr-1 rounded">
                    <span style="height:.5rem;width: .5rem;display: inline-block;border-radius: .125rem;"
                      class="bg-primary" v-if="target.label == item.label"></span>
                  </span>
                </div>
              </div>
              <div style="width:calc(100% - 40px)" class="text-left">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="flex items-center justify-center w-full h-full" v-else>
            <div class="text-gray-400">暂无数据</div>
          </div>
        </div>
        <div style="height:60px;" class="flex items-center justify-center popup_btn">
          <div class="w-full">
            <el-button style="width: 45%;" @click="handleClose">取消</el-button>
            <el-button type="primary" style="width: 45%;" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { debounce } from "@/utils/utils.js"
export default {
  data() {
    return {
      direction: "btt",
      current: -1,
      target: {},
      drawer: false,
      dataList: [],
      keyword: ""
    };
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    list: {
      handler: function () {
        this.dataList = []
        this.dataList.push(...this.list)
      },
      immediate: true
    },
    keyword: function () {
      debounce(() => {
        if (this.keyword == '') {
          this.dataList = []
          this.dataList.push(...this.list)
          return
        }
        let list = this.list.filter(item => {
          let title = item.label.toLowerCase()
          let keyword = this.keyword.toLowerCase()
          return title.includes(keyword)
        })
        this.dataList = []
        this.dataList.push(...list)
      }, 800)
    }
  },
  components: {},
  methods: {
    open() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    selectItem(item) {
      this.target = item
    },
    submit() {
      this.drawer = false;
      this.target.label
        ? this.$emit("select", this.target)
        : this.handleClose()
    },
  },
};
</script>

<style lang="scss" scoped>
.popup_btn {
  box-sizing: border-box;
  border-top: 0.0625rem solid var(--border-base);
}

.item:not(:last-child) {
  border-bottom: 0.0625rem solid var(--border-base);
}
</style>
