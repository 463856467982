/*
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-04-17 09:31:53
 * @FilePath: /com.huizhouyiren.B0805/src/store/chat.js
 * @Description: 聊天模块
 */
// function getCurrentDay() {
//   let date = new Date();
//   let year = date.getFullYear();
//   let month = date.getMonth() + 1;
//   let day = date.getDate();
//   month = month > 9 ? month : "0" + month;
//   day = day < 10 ? "0" + day : day;
//   return year + "-" + month + "-" + day;
// }
import { getTime, generateRandId } from "@/utils/utils.js";
import request from "../api/request";
import requestPath from "../api/path";
export default {
  namespaced: true,
  state: {
    userInfo: {},
    socket: null,
    setIntervalWesocketPush: null, // 心跳包定时器
    messageQueue: [], // 消息队列
    chatList: [], // 会话列表
    message: {
      // 聊天消息列表
      list: [],
      maxPage: 1,
      page: 1,
      size: 40,
    },
    newMessageCount: 0, // 新消息数量（未读消息）
    quickReply: [], // 快捷回复列表
    currentSessionUser: {}, // 当前聊天用户
    imUserId: "", // 用户的im ID
    unreadFlag: false, // 未读消息数
    serviceList: [], // 客服列表
  },
  mutations: {
    /**
     * @description: 更新客服列表
     * @param {*} state
     * @param {*} list 客服列表
     * @return {*}
     */

    updateServiceList(state, list) {
      state.serviceList = list;
    },
    // 更新用户的im id
    updateImUserId(state, id) {
      state.imUserId = id;
    },
    /**
     * @Descripttion: 更新当前聊天用户
     * @param {*} state
     * @param {object} user 用户
     * @return {*}
     */
    updateCurrentSessionUser(state, user) {
      state.currentSessionUser = user;
      // 处理未读标识，当切换了用户时，则执行
      if (user.unread) {
        let index = state.chatList.findIndex((item) => {
          return item.account == user.account;
        });
        if (index > -1) {
          let chatList = state.chatList;
          chatList[index].unread = 0;
          state.chatList = [];
          state.chatList = chatList;
        }
        let unreadIndex = state.chatList.findIndex((item) => {
          return item.unread > 0;
        });
        let flag = unreadIndex > -1 ? true : false;
        this.commit("chat/updateUnredFlag", flag);
      }
    },
    /**
     * @name: 更新快捷回复列表
     * @param {*} state
     * @param {array} list 列表
     * @return {*}
     */
    updateQuickReply(state, list) {
      state.quickReply = list;
    },
    /**
     * @name: 更新会话列表
     * @param {*} state
     * @param {array} list  消息列表
     * @param {string} type  类型 init(用于初始化会话列表)  update(更新)
     * @return {*}
     */

    updateChatList(state, { list, type }) {
      let unreadList = [];
      let readList = [];
      switch (type) {
        case "init": // 用于初始化会话列表时
          state.chatList = [];

          list = list.sort((a, b) => {
            return b.lastSendTime - a.lastSendTime;
          });

          list.forEach((item) => {
            item.unread ? unreadList.push(item) : readList.push(item);
          });
          // console.log(unreadList, readList);
          state.chatList.push(...[...unreadList, ...readList]);
          state.chatList.length > 0
            ? this.commit("chat/updateCurrentSessionUser", state.chatList[0])
            : "";
          break;
        case "update": // 用于更新会话列表时
          // eslint-disable-next-line no-case-declarations
          let chatList = [...state.chatList];
          // eslint-disable-next-line no-case-declarations
          let obj = {};
          list.forEach((item) => {
            let index = state.chatList.findIndex((target) => {
              return target.id == item.id;
            });
            index > -1 ? (obj = chatList[index]) : "";
            index > -1 ? chatList.splice(index, 1) : "";
          });

          console.log(obj);
          obj.unread = 1;
          state.chatList = [];
          state.chatList.push(obj);
          state.chatList.push(...chatList);
          break;
      }
      this.commit("chat/updateUnredFlag");
    },
    /**
     * @Descripttion: 更新新消息标识
     * @param {*} state
     * @return {*}
     */
    updateUnredFlag(state) {
      let index = state.chatList.findIndex((item) => {
        return item.unread > 0;
      });
      state.unreadFlag = index > -1 ? true : false;
    },
    // 更新聊天消息列表
    updateMessageList(state, payload) {
      let messageList;
      let { type } = payload;
      let index = -1;
      switch (type) {
        case "init": // 初始化获取聊天
          state.message.maxPage = payload.maxPage;
          state.message.list = [];
          state.message.list.push(...payload.list);
          window.$EventBus.$emit("scrollToButtom");
          break;
        case "loadmore": // 加载更多
          messageList = [...state.message.list];
          state.message.list = [...payload.list, ...messageList];
          window.$EventBus.$emit("scrollToButtom");
          break;
        case "retraction": // 撤回消息(自身)
          messageList = [...state.message.list];
          index = messageList.findIndex((item) => {
            return item.id == payload.msgId;
          });
          if (index > -1) {
            messageList.splice(index, 1);
            state.message.list = [];
            state.message.list.push(...messageList);
          }
          break;
        case "receiveRetraction": // 撤回消息(对方)
          state.message.list = [];
          state.message.list.push(...payload.list);
          break;
        case "update": // 更新聊天列表（有新消息时）
          state.message.list.push(...payload.list);
          window.$EventBus.$emit("scrollToButtom");
          break;
      }
    },
    /**
     * @description: 自己发送的消息更新到聊天历史中
     * @param {*} state
     * @param {*} message
     * @return {*}
     */

    messageUpperWindow(state, message) {
      state.message.list.push(message);
    },
    // 更新未读消息数量
    updateNewMessageCount(state, count) {
      state.newMessageCount = count;
    },
    // 接收到的消息
    receiveMessage(state, { res, rootState }) {
      if (res.data != "连接成功") {
        let message = JSON.parse(res.data);
        let user_id;
        let targetIndex;
        let currentIndex;
        // console.log("socket", message);
        switch (message["type"]) {
          // 服务端ping客户端
          case "ping":
            this.dispatch("chat/sendMessage", { type: "pong" });
            break;
          // 登录 更新用户列表
          case "init":
            request({
              url: requestPath.bindClientIdAPI,
              method: "POST",
              data: {
                client_id: message["client_id"],
                user_id: state.imUserId,
              },
            }).then((res) => {
              this.dispatch("chat/sendMessage", {
                type: "bindUid",
                user_id: state.imUserId,
              });
            });
            break;
          case "simple": // 如果是自己发送的消息则不需要提示
            user_id = message.data.fromUser.user_id;
            if (user_id != state.imUserId) {
              console.log("获取到的rootState", rootState);
              if (user_id == state.currentSessionUser.user_id) {
                this.commit("chat/updateMessageList", {
                  type: "update",
                  list: [message.data],
                });
              }
              window.$EventBus.$emit("newsNotify");
              // let chatList = [...state.chatList];
              // let index = chatList.findIndex((item) => {
              //   return item.user_id == user_id;
              // });
              // if (index > -1) {
              //   chatList.splice(index, 1);
              // }
              // chatList.unshift({
              //   ...message.data.fromUser,
              //   unread: 1,
              // });
              this.commit("chat/updateChatList", {
                list: [
                  {
                    ...message.data.fromUser,
                    unread: 1,
                  },
                ],
                type: "update",
              });
              let { userInfo, autoReplaySetting } = rootState;
              if (userInfo.isService && autoReplaySetting.automaticReply) {
                // 是客服
                let {
                  automaticReplyContent,
                  automaticReplyEndTime,
                  automaticReplyTime,
                } = autoReplaySetting;
                let date = getTime("simple", "-");
                let currentTime = new Date().getTime();
                let startTime = new Date(
                  date + " " + automaticReplyTime
                ).getTime();
                let endTime = new Date(
                  date + " " + automaticReplyEndTime
                ).getTime();
                // let startTime = new Date(date + " " + "14:00:00").getTime();
                // let endTime = new Date(date + " " + "08:00:00").getTime();
                let inRange = false;
                if (endTime < startTime) {
                  inRange = currentTime > startTime || currentTime < endTime;
                } else {
                  inRange = currentTime > startTime && currentTime < endTime;
                }
                if (inRange) {
                  this.dispatch("chat/sendRequestMessage", {
                    content: automaticReplyContent,
                    type: "text",
                    toContactId: user_id,
                  });
                }
              }
            } else {
              // 设置消息为已读
              this.dispatch("chat/setMsgIsRead");
            }
            break;
          case "undoMessage": // 撤回消息
            if (message.data.from_user == state.currentSessionUser.user_id) {
              let messageList = [...state.message.list];
              let msgIndex = messageList.findIndex((item) => {
                return message.data.id == item.id;
              });
              if (msgIndex > -1) {
                messageList.splice(msgIndex, 1);
                this.commit("chat/updateMessageList", {
                  type: "receiveRetraction",
                  list: messageList,
                });
              }
              this.commit("chat/updateMessageList", {
                type: "update",
                list: [
                  {
                    ...message.data,
                    fromUser: {
                      user_id: message.data.from_user,
                    },
                  },
                ],
              });
            }
            break;
          //上线、下线
          case "isOnline":
            targetIndex = state.serviceList.findIndex(
              (item) => message.data.id == item.user_id
            );
            // 判断是否是客服的上下线通知
            if (targetIndex > -1) {
              // 延迟获取客服列表和在线状态，避免后台更新不及时
              setTimeout(() => {
                this.dispatch("chat/searchOnlineState", {
                  callback: (res) => {
                    // console.log("res.data", res.data);
                    currentIndex = res.data.findIndex(
                      (item) => item.user_id == state.currentSessionUser.user_id
                    );
                    // 判断用户当前对接的客服是否是在线状态，如果在线则切换客服
                    if (
                      currentIndex > -1 &&
                      !res.data[currentIndex].is_online
                    ) {
                      //  过滤在线客服
                      let serviceList = res.data.filter((item) => {
                        // console.log("item", item);
                        return item.is_online;
                      });
                      // console.log("serviceList", serviceList);
                      // 有在线的客服则切换客服，没有则不切换
                      if (serviceList.length > 0) {
                        let index;
                        serviceList.length == 1
                          ? (index = 0)
                          : (index = Math.floor(
                              Math.random() * serviceList.length
                            ));
                        // console.log("index", index);
                        this.commit("chat/updateCurrentSessionUser", {
                          realname: "客服",
                          user_id: serviceList[index].user_id,
                        });
                        let service = localStorage.getItem("saveServe")
                          ? JSON.parse(localStorage.getItem("saveServe"))
                          : ""; // 本地存储的上一次聊天的客服
                        let userInfo = localStorage.getItem("userInfo")
                          ? JSON.parse(localStorage.getItem("userInfo"))
                          : "";
                        // console.log(userInfo, service);
                        if (!userInfo.isService) {
                          if (service) {
                            service[userInfo.userId] =
                              serviceList[index].user_id;
                            localStorage.setItem(
                              "saveServe",
                              JSON.stringify(service)
                            );
                          } else {
                            localStorage.setItem(
                              "saveServe",
                              JSON.stringify({
                                [userInfo.userId]: serviceList[index].user_id,
                              })
                            );
                          }
                        }
                      }
                    }
                  },
                });
              }, 1000);
            }
            break;
          default:
            break;
        }
      }
    },
  },
  actions: {
    // 创建socket连接
    createSocket({ state, commit, rootState }) {
      if (typeof WebSocket != "function") {
        alert(
          "您的浏览器不支持Websocket通信协议,请更换浏览器为Chrome或者Firefox再次使用!"
        );
        return;
      }
      state.userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).data
        : "";
      state.socket && state.socket.close();
      if (!state.socket) {
        // console.log("建立websocket连接");
        // http://im.42.huizhouyiren.com   ws://192.168.0.42:8282/wss   106.52.28.186:28282 ws://im.42.huizhouyiren.com:8082/wss
        // wss://域名.com/wss
        state.socket = new WebSocket(`wss://im.firebat.com.cn/wss`);
        // state.socket = new WebSocket(`wss://im.42.huizhouyiren.com:28282/wss`);
        state.socket.onopen = () => {
          // this.dispatch("chat/sendPing");
        };
        state.socket.onmessage = (e) => {
          commit("receiveMessage", { res: e, rootState });
        };
        state.socket.onerror = (e) => {
          // console.log("连接失败重连中");
          state.socket.close();
          clearInterval(state.setIntervalWesocketPush);
          state.setIntervalWesocketPush = null;
          if (state.socket.readyState !== 3) {
            state.socket = null;
            this.dispatch("chat/createSocket");
          }
        };
        state.socket.onclose = () => {
          // console.log("websocket已断开....正在尝试重连");
          clearInterval(state.setIntervalWesocketPush);
          state.setIntervalWesocketPush = null;
          if (state.socket.readyState !== 2) {
            state.socket = null;
            this.dispatch("chat/createSocket");
          }
        };
      } else {
        console.log("websocket已连接");
      }
    },
    // 发送消息
    sendMessage({ state }, payload) {
      let data = {
        ...payload,
      };
      if (state.socket !== null && state.socket.readyState === 3) {
        state.socket.close();
        this.dispatch("chat/createSocket");
      } else if (state.socket.readyState === 1) {
        state.socket.send(JSON.stringify(data));
      } else if (state.socket.readyState === 0) {
        // this.dispatch("chat/resendMessage", message);
        state.messageQueue.push(data); // 添加到待发消息队列中
      }
    },
    // 消息重发
    resendMessage({ state }) {
      let messageQueue = [...state.messageQueue];
      state.messageQueue.forEach((item, index) => {
        if (state.socket.readyState === 1) {
          state.socket.send(JSON.stringify(item));
          messageQueue.shift();
        } else if (state.socket.readyState === 0) {
          return;
        }
      });
      state.messageQueue = messageQueue;
    },
    // 发送心跳包
    sendPing({ state }, time = 5000) {
      clearInterval(state.setIntervalWesocketPush);
      let ping = JSON.stringify({
        handlerType: 100,
      });
      state.socket.send(ping);
      state.setIntervalWesocketPush = setInterval(() => {
        state.socket.send(ping);
      }, time);
    },
    /**
     * @description: 设置消息为已读
     * @param {*} state
     * @return {*}
     */
    setMsgIsRead({ state }) {
      let data = [];
      state.message.list.forEach((item) => {
        if (item.is_read == 0 && item.fromUser.id != state.imUserId) {
          data.push(item);
        }
      });
      request({
        url: requestPath.setMsgIsRead,
        method: "post",
        data: {
          is_group: false,
          toContactId: state.currentSessionUser.user_id,
          messages: data,
          fromUser: state.imUserId,
        },
      }).then((res) => {
        let chatList = [...state.chatList];
        let index = chatList.findIndex((item) => {
          return state.currentSessionUser.user_id == item.id;
        });
        if (index > -1) {
          chatList[index].unread = 0;
        }
        state.chatList = [];
        state.chatList.push(...chatList);
        // if (res.code == 0) {
        //   this.dispatch("chat/chatListDeal");
        // }
      });
    },
    /**
     * 聊天列表处理（接收到新消息后）
     */
    chatListDeal({ rootState }) {
      this.dispatch("chat/getChatList", { page: 1, type: "update" });
    },
    /**
     * 获取会话列表
     * @param {Number} page 页码数
     * @param {function} callback 回调函数
     */
    getChatList({ commit, rootState }, { page, callback, type = "init" }) {
      request({
        method: "get",
        url: requestPath.getContacts,
        data: {
          page,
        },
      })
        .then((res) => {
          // console.log("获取会话列表", res);
          if (res.code == 0) {
            commit("updateChatList", { list: res.data, type });
            callback ? callback(res.data) : "";
          } else {
            rootState.$EventBus.$emit("toast", {
              type: "error",
              message: "获取会话列表失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取会话列表", error);
        });
    },

    // 获取聊天记录
    /**
     * @param {*} state
     * @param {*} commit
     * @param {*} callback
     * @param {*} type
     * @return {*}
     */
    getMessageList({ state, commit, rootState }, { callback, type }) {
      if (type == "init") {
        state.message.page = 1;
      } else {
        state.message.page++;
      }
      let data = {
        toContactId: state.currentSessionUser.user_id,
        is_group: false,
        pageSize: state.message.page,
        listRows: state.message.size,
      };
      rootState.userInfo.isService ? (data.iskf = 1) : "";
      request({
        method: "post",
        url: requestPath.getMessageList,
        data,
      })
        .then((res) => {
          // console.log("获取聊天记录", res);
          if (res.code == 0) {
            commit("updateMessageList", {
              type,
              list: res.data,
              maxPage: Math.ceil(res.count / state.message.size),
            });
            callback ? callback() : "";
          } else {
            window.$EventBus.$emit("toast", {
              type: "error",
              message: "获取聊天记录失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取聊天记录", error);
        });
    },
    /**
     * @Descripttion: 获取快捷回复
     * @param {*} commit
     * @return {*}
     */

    getQuickReply({ commit }) {
      request({
        method: "get",
        url: requestPath.fireQuick,
        data: {},
      })
        .then((res) => {
          // console.log("获取快捷回复", res);
          if (res.code == 0) {
            commit("updateQuickReply", res.data);
          } else {
            window.$EventBus.$emit("toast", {
              type: "error",
              message: "获取快捷回复失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取快捷回复", error);
        });
    },
    /**
     * @name: 查询用户的im ID
     * @param {*} commit
     * @param {*} rootState
     * @param {object} payload 要查询的用户对象
     * @return {*}
     */
    getUserInfoByAccount({ commit, rootState }, payload) {
      request({
        method: "get",
        url: requestPath.getUserInfoByAccount,
        params: {
          account: payload.id ? payload.id : rootState.userInfo.userId,
        },
      })
        .then((res) => {
          // console.log("查询用户的im ID", res);
          if (res.code == 0) {
            payload.type == "searchService"
              ? ""
              : commit("updateImUserId", res.data.user_id);
            payload.callback ? payload.callback(res.data.user_id) : "";
          } else {
            window.$EventBus.$emit("toast", {
              type: "error",
              message: res.msg,
            });
            payload.errorback ? payload.errorback() : "";
          }
        })
        .catch((error) => {
          payload.errorback ? payload.errorback() : "";
          console.log("查询用户的im ID", error);
        });
    },
    /**
     * @name: 消息撤回
     * @param {*} commit
     * @param {number} msgId 消息的ID
     * @return {*}
     */
    messageRetraction({ commit, state }, msgId) {
      request({
        method: "post",
        url: requestPath.messageRetraction,
        data: {
          id: msgId,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            commit("updateMessageList", { msgId, type: "retraction" });
            commit("updateMessageList", {
              type: "update",
              list: [
                {
                  type: "event",
                  id: new Date().getTime(),
                  content: "你撤回了一条消息",
                  fromUser: {
                    user_id: state.imUserId,
                  },
                },
              ],
            });
          } else {
            window.$EventBus.$emit("toast", {
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log("消息撤回", error);
        });
    },
    /**
     * @name: 获取客服列表
     * @param {*} _
     * @param {function} callback 回调函数
     * @return {*}
     */
    getServiceList(_, callback) {
      request({
        method: "get",
        url: requestPath.serviceList,
        params: {},
      })
        .then((res) => {
          //
          if (res.code == 0) {
            callback(res.data);
          } else {
            window.$EventBus.$emit("toast", {
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log("消息撤回", error);
        });
    },
    // 查询客服在线状态
    searchOnlineState({ commit }, { callback }) {
      this.dispatch("chat/getServiceList", (data) => {
        if (data.length > 0) {
          let list = [];
          data.forEach((item) => {
            list.push(item.userId);
          });
          request({
            url: requestPath.getUserOnlineStatus,
            method: "post",
            data: {
              uids: list.join(","),
            },
          }).then((res) => {
            commit("updateServiceList", res.data);
            callback ? callback(res) : "";
          });
        }
      });
    },
    /**
     * @description: 调用接口发送消息
     * @param {*} content
     * @param {*} type
     * @return {*}
     */

    sendRequestMessage(
      { state, commit, rootState },
      { content, type, toContactId }
    ) {
      let data = {
        id: generateRandId(),
        type,
        sendTime: new Date().getTime(),
        content,
        toContactId,
        is_group: 0,
        fromUser: {
          //如果 id == this.user.id消息会显示在右侧，否则在左侧
          id: state.imUserId,
          displayName: rootState.userInfo.userName,
          avatar: rootState.userInfo.userPicture,
          user_id: state.imUserId,
        },
      };
      request({
        url: requestPath.sendMessageAPI,
        method: "POST",
        data,
      }).then((res) => {
        if (res.code == 0) {
          console.log(state.currentSessionUser, toContactId);
          if (state.currentSessionUser.id == toContactId) {
            commit("messageUpperWindow", data);
          }
          window.$EventBus.$emit("scrollToButtom");
        } else {
          window.$EventBus.$emit("toast", {
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
  getters: {},
};
