/*
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-06-21 15:43:57
 * @FilePath: /com.huizhouyiren.B0805/src/api/path.js
 * @Description: 请求路径
 */
const requestPath = {
  // 注册
  register: "/fire/auth/register",

  // 登录
  login: "/fire/auth/login",

  // 获取短信验证码
  getCode: "/fire/auth/sendSmsCode",

  //手机验证码找回密码
  smsCodeFoundPwd: "/fire/auth/retrievePwd",

  // 密保找回验证码
  encryptedDFoundPwd: "/fire/auth/secretSecurity",

  // logo获取
  findLogo: "/fire/home/findLogo",

  // 首页海报获取
  findPoster: "/fire/home/findPoster",

  // 海报详情页获取
  findPosterDetail: "/fire/home/findPosterDetail",

  // 获取轮播图
  getBanner: "/fire/getCarousel",

  // 品牌中心
  getBrand: "/fire/home/findBrand",

  // 获取门店
  getStore: "/fire/article/store",

  // 获取壁纸
  getWallpaper: "/fire/work/getWallpaper",

  // 获取驱动查询条件
  getDriveCategory: "/fire/getDriveCategory",

  // 根据驱动id查询
  getByCategoryId: "/fire/getByCategoryId",

  // 获取驱动分类
  findDriveCategory: "/fire/findDriveCategory",

  // 获取用户信息
  getUserInfo: "/fire/user/userInfo",

  // 修改用户信息
  userUpdate: "/fire/user/userUpdate",

  // 浏览历史
  browsingHistory: "/fire/user/browsingHistory",

  // 收藏
  collect: "/fire/user/collect",

  // 我的收藏
  collectList: "/fire/user/collectList",

  // 取消收藏
  delCollect: "/fire/user/delCollect",

  // 积分
  integral: "/fire/integral/find",

  // 积分商品展示
  integralProduct: "/fire/integral/findProduct",

  // 积分商品兑换纪录
  exchangeRecord: "/fire/integral/findExchange",

  // 积分商品兑换记录详情查看
  exchangeDetail: "/fire/integral/findExchangeDetail",

  // 积分商品兑换
  exchangeProduct: "/fire/integral/findProductExchange",

  // 我的设备
  equipment: "/fire/equipment",

  // 设备信息
  deviceInfo: "/fire/deviceInfo",

  // 绑定设备
  bindDevice: "/fire/bindDevice",

  // 解绑设备
  unbindDevice: "/fire/removeBindDevice",

  // 获取设备维修列表信息
  findRepairList: "/fire/findRepairList",

  // 真伪查验
  authenticity: "/fire/authenticity",

  // 设备维修
  repair: "/fire/repair",

  // 获取收货地址
  getAddress: "/fire/shippingAddress",

  // 添加收货地址
  addAddress: "/fire/addAddress",

  // 删除地址
  deleteAddress: "/fire/removeAddress",

  // 获取底部信息
  findBottom: "/fire/home/findBottom",

  // 品牌中心信息展示
  findBrand: "/fire/home/findBrand",

  // 新闻中心信息展示
  findTidings: "/fire/home/findTidings",

  // 获取保修单下拉框数据
  getDropDownBoxData: "/fire/getDropDownBoxData",

  // 寄修申请
  sendRepairApplication: "/fire/sendRepairApplication",

  // 修改寄修申请
  updRepairApplication: "/fire/updRepairApplication",

  // 获取七牛授权token
  getAccessToken: "/fire/common/getAccessToken",

  // 获取商品分类
  getCommodityClassify: "/fire/commodityClassify",

  // 根据分类查询商品
  getClassifyProducts: "/fire/classify",

  // 获取活动商品
  getEventGoods: "/fire/getEventGoods",

  // 获取商品详情
  GoodsDetails: "/fire/GoodsDetails",

  // 实名认证
  userAuth: "/fire/user/userAuth",

  // 实名认证信息
  userAuthDetail: "/fire/user/userAuthDetail",

  // 实名认证修改
  userAuthUpdate: "/fire/user/userAuthUpdate",

  // 绑定（修改邮箱）
  bindEmail: "/fire/user/bindEmail",

  // 绑定手机
  bindPhone: "/fire/user/bindPhone",

  // 修改账号密码
  updPassword: "/fire/user/updPassword",

  // 提交建议和反馈
  addFeedback: "/fire/user/addFeedback",

  // 搜索商品
  search: "/fire/search",

  // 获取搜索默认词
  searchDefaultWords: "/fire/home/searchDefaultWords",

  // 获取设备物流信息
  logistics: "/fire/logistics/getLogistics",

  // 获取社区
  getCommunity: "/fire/getCommunity",

  // 获取社区
  getCommunityInfo: "/fire/getCarouselDetail",

  // 记录浏览历史
  recordHistory: "/fire/home/record",

  // 删除浏览历史
  delHistory: "/fire/user/delHistory",

  // 开始抽奖
  lottery: "/fire/lottery",

  // 获取抽奖奖品信息
  findLottery: "/fire/lottery/findLottery",

  //中奖纪录
  lotteryRecord: "/fire/lottery/findLotteryRecord",

  // 中奖领取
  lotteryReceive: "/fire/lottery/lotteryReceive",

  // 确认收货
  receiptOfGoods: "/fire/lottery/confirmTheReceiptOfGoods",

  // 签到
  signIn: "/fire/signIn",

  // 用户今日是否签到
  ifSignIn: "/fire/signIn/ifSignIn",

  // 获取签到信息
  signInInfo: "/fire/signIn/findSignIn",

  // 获取签到规则
  signRule: "/fire/signIn/findSignRule",

  //
  // getResource: "/fire/getResource",

  // 获取 资源下载 门店 品牌 自定义页面内容
  getResourceDetail: "/fire/getResourceDetail",

  // 底部信息详情展示
  findBottomDetail: "/fire/home/findBottomDetail",

  // 获取协议详情
  protocolDetail: "/fire/protocol/protocolDetail",

  // 用户填写快递单号
  warrantyOrder: "/fire/warrantyOrder",

  // 用户确认收货
  userReceipt: "/fire/userReceipt",

  // 获取快递公司编码
  getkuaidiCode: "/fire/getkuaidiCode",

  //  查询快递信息
  getExpress: "/fire/common/getExpress",

  // 获取设备物流表单
  repairFrom: "/fire/logistics/repairFrom",

  // 维修详情
  equipmentDetails: "/fire/equipmentDetails",

  // 客服快捷消息
  fireQuick: "/fire/common/fireQuick",

  //  获取客服列表
  serviceList: "/fire/common/serviceList",

  // 客服查询用户维修列表
  equipmentList: "/fire/equipmentList",

  // 维修详情（客服）
  serveEquipmentDetail: "/fire/equipmentDetail",

  // 获取自动回复设置
  automaticReply: "/fire/common/automaticReply",

  // 获取产品列表
  productList: "/fire/productList",

  // 获取销售渠道
  salesList: "/fire/salesList",

  /**
   * Im相关接口
   */
  // 绑定用户ID以及初始化消息服务
  bindClientIdAPI: "/im/common/pub/bindUid",

  // 发送聊天信息
  sendMessageAPI: "/im/enterprise/im/sendMessage",

  // 获取会话列表
  getContacts: "/im/enterprise/im/getFriends", // getFriends   getContacts

  // 获取聊天记录
  getMessageList: "/im/enterprise/im/getMessageList",

  // 查询IM的ID
  getUserInfoByAccount: "/im/enterprise/im/getUserInfoByAccount",

  // 撤回消息
  messageRetraction: "/im/enterprise/im/undoMessage",

  // 获取在线状态
  getUserOnlineStatus: "/im/enterprise/im/getUserOnlineStatus",

  // 设置消息为已读
  setMsgIsRead: "/im/enterprise/im/setMsgIsRead",

  // 用户下线
  offline: "/im/common/pub/offline",
};

// 代理前缀的处理
Object.keys(requestPath).forEach((key) => {
  let arr = requestPath[key].split("/");
  arr[1] == "im"
    ? ""
    : (requestPath[key] = process.env.VUE_APP_BASE_API + requestPath[key]);
});
export default requestPath;
