/*
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-20 17:20:00
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-20 17:25:24
 * @FilePath: /com.huizhouyiren.B0805/src/utils/notify.js
 * @Description: 封装的notification
 */
/*
 * @param {string} title - 消息标题
 * @param {object} options - 消息配置
 * @param {number} duration - 消息时长
 * @param {function} onclick
 * @param {function} onshow
 * @param {function} onclose
 * @param {function} onerror
 * @return {object}
 */
export default async function createNotify(data) {
  // 一些判断
  if (window.Notification) {
    if (Notification.permission === "granted") {
      return notify(data);
    } else if (Notification.permission === "default") {
      let [err, status] = await Notification.requestPermission()
        .then((status) => [null, status])
        .catch((err) => [err, null]);
      return err || status === "denied" ? Promise.reject() : notify(data);
    }
  }
  // 构造实例
  function notify(data) {
    let { title, options, duration } = data;
    options.requireInteraction = true;
    let notification = new Notification(title, options);
    setTimeout(() => notification.close(), duration || 5000);
    // 绑定事件
    let methods = ["onclick", "onshow", "onclose", "onerror"];
    for (let i = 0; i < methods.length; i++) {
      let method = methods[i];
      if (typeof options[method] === "function") {
        notification[method] = options[method];
      }
    }
    return notification;
  }
  return Promise.reject();
}
