<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-15 10:18:27
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-15 10:32:18
 * @FilePath: /com.huizhouyiren.B0805/src/components/navbarText/navbarText.vue
 * @Description: 分级导航文字
-->
<template>
    <div class="text-left text-gray-600">
        <div v-for="(item, index) in list" :key="index" style="display: inline-block;">
            <span @click="goPage(item.path)" :class="item.path ? 'cursor-pointer' : ''">{{ item.title }} <span
                    v-if="index < list.length - 1">-</span></span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        navbarList: { // 导航数据数组
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            list: [{ title: "首页", path: '/' }]
        }
    },
    created() {
        this.list.push(...this.navbarList)
    },
    methods: {
        /**
         * @description: 页面跳转
         * @param {*} path 页面路径
         * @return {*}
         */
        goPage(path) {
            path ? this.$router.push({ path }) : ""

        }
    },
}
</script>