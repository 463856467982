import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

import "./assets/css/reset.css";
import "./assets/tailwind.css";

import request from "./api/request";
import requestPath from "./api/path";

import NavbarText from "@/components/navbarText/navbarText.vue";

// import Element from "element-ui";
import CustomDatePicker from "./components/datepicker/DatePicker.vue";
import MobilePopup from "./components/mobilePopup/MobilePopup.vue";
import VDistpicker from "v-distpicker";

import { Alert, Confirm, Toast } from "wc-messagebox";
import "wc-messagebox/style.css";

// 按需引入(抽奖)
import LuckDraw from "vue-luck-draw";
Vue.use(LuckDraw);

Vue.use(Alert);
Vue.use(Confirm);
Vue.use(Toast);

import {
  Form,
  FormItem,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  TimePicker,
  DatePicker,
  Input,
  Select,
  Option,
  Col,
  Switch,
  Upload,
  Dialog,
  Button,
  Pagination,
  Timeline,
  TimelineItem,
  Cascader,
  Drawer,
  Carousel,
  CarouselItem,
  Message,
  MessageBox,
  Empty,
  Notification,
} from "element-ui";

// element-ui组件
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(TimePicker);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Col);
Vue.use(Switch);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Cascader);
Vue.use(Drawer);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Empty);

Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$eleconfirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
// Vue.use(Element);

// 事件总线
Vue.prototype.$EventBus = new Vue();
window.$EventBus = Vue.prototype.$EventBus;

Vue.component("CustomDatePicker", CustomDatePicker);
Vue.component("MobilePopup", MobilePopup);
Vue.component("VDistpicker", VDistpicker);
Vue.component("NavbarText", NavbarText);

// 封装的axios请求
Vue.prototype.$request = request;
// 请求路径
Vue.prototype.$requestPath = requestPath;
// 判断移动端还是pc端的标识
Vue.prototype.isMobile = false;
// 七牛上传地址
Vue.prototype.qiniuUrl = "https://up-z2.qiniup.com";

// 判断字符串是否是非空字符
Vue.prototype.isNull = (str) => {
  if (str == "") return true;
  var regu = "^[ ]+$";
  var re = new RegExp(regu);
  return re.test(str);
};

// 校验手机号码
Vue.prototype.checkPhone = (phone) => {
  // if (/^1[3|4|5|8][0-9]\d{4,8}$/.test(phone)) {
  //   return true;
  // } else {
  //   return false;
  // }
  if (/^1[3-9]\d{9}$/.test(phone)) {
    return true;
  } else {
    return false;
  }
};

// 日期转换
Vue.prototype.transformDate = (timestamp, type, text) => {
  /**
   * @timetamp  时间戳
   * @type 类型
   * @text 拼接的字符串
   */
  let date = new Date(timestamp);
  let Y = "";
  let M = "";
  let D = "";
  if (type == "Chinese") {
    Y = date.getFullYear() + "年";
    M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "月";
    D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
    return Y + M + D;
  }
  if (type == "full") {
    Y = date.getFullYear() + text;
    M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + text;
    D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    const h =
      date.getHours() < 10
        ? "0" + date.getHours() + ":"
        : date.getHours() + ":";
    const m =
      date.getMinutes() < 10
        ? "0" + date.getMinutes() + ":"
        : date.getMinutes() + ":";
    const s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 秒
    return Y + M + D + h + m + s;
  }

  if (type == "simple") {
    Y = date.getFullYear() + text;
    M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + text;
    D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return Y + M + D;
  }

  if (type == "time") {
    const h =
      date.getHours() < 10
        ? "0" + date.getHours() + ":"
        : date.getHours() + ":";
    const m =
      date.getMinutes() < 10
        ? "0" + date.getMinutes() + ":"
        : date.getMinutes() + ":";
    const s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 秒
    return h + m + s;
  }
};

// 图片下载的方法
Vue.prototype.canvasDownload = (url) => {
  let arr = url.split("/");
  let name = arr[arr.length - 1];
  var canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d"),
    img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function() {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    let a = document.createElement("a");
    a.href = dataURL;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      canvas = null;
    }, 1000);
  };
  img.src = url;
};

// 判断打开网页的环境
Vue.prototype.browserRedirect = () => {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    // 手机端
    return true;
  } else {
    //电脑端
    return false;
  }
};
// 判断是否是在微信环境中打开
Vue.prototype.judgeWx = () => {
  var is_weixin = (function() {
    return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
  })();
  if (is_weixin) {
    return true;
  } else {
    return false;
  }
};

// 解析url参数
Vue.prototype.getUrlParam = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null; //返回参数值
};

Vue.prototype.debounce = (fn, wait) => {
  var timer = null;
  return function() {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn, wait);
  };
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
